import React, { Suspense, lazy } from 'react';
import { graphql } from 'gatsby'
import Helmet from 'react-helmet'
import Layout from 'components/layout'
const CiberseguridadCol = lazy(() => import('sections/hero/CiberseguridadColombia.js'));
const ServicesCiberCol = lazy(() => import('sections/services/ServicesCiberCol.js'));
const ServicesGobierno = lazy(() => import('sections/services/ServicesGobierno.js'));
const BotonSanGeo = lazy(() => import('sections/services/BotonSanGeo.js'));
const Politica = lazy(() => import('sections/hero/Politica.js'));
const Sgsi = lazy(() => import('sections/about/Sgsi.js'));
const Iso27001 = lazy(() => import('sections/about/Iso27001.js'));
const ServicesUbicacion = lazy(() => import('sections/services/ServicesUbicacion.js'));
const SSLsanBlanco = lazy(() => import('sections/about/SSLsanBlanco.js'));
const Hola = lazy(() => import('sections/hero/Hola.js'));
const SelloSitelock = lazy(() => import('sections/about/SelloSitelock.js'));
const Llamenos = lazy(() => import('sections/hero/Llamenos.js'));
const FaqCiberseguridad = lazy(() => import('sections/services/FaqCiberseguridad.js'));
const ContactCreative = lazy(() => import('sections/contact/ContactCreative.js'));
const Footer = lazy(() => import('sections/services/Footer.js'));

class Index extends React.Component {

  render() {


    return (
      <div>
        <Helmet>
          <title>Empresa de Ciberseguridad en Colombia | Bogotá Cali Medellín</title>
          <meta name="description" content="Protege tu negocio con expertos en ciberseguridad en Colombia. Soluciones contra ciberdelincuentes y cumplimiento de normas sin complicaciones." />
        </Helmet>
        <Layout>
          <Suspense fallback={<div>Cargando...</div>}>
            <CiberseguridadCol />
            <ServicesCiberCol />
            <ServicesGobierno />
            <Politica />
            <Hola />
            <Sgsi />
            <Iso27001 />
            <ServicesUbicacion />
            <SelloSitelock />
            <SSLsanBlanco />
            <FaqCiberseguridad />
            <BotonSanGeo />
            <Llamenos />
            <ContactCreative />
            <Footer />
          </Suspense>
        </Layout>
      </div>
    )
  }
}

export default Index

export const creativeVideoTwoQuery = graphql`
  query {
    site {
      meta: siteMetadata {
        title
        description
      }
    }
  }
`